<!--
 * @Description: 房间显示
 * @Date: 2022-03-16 17:40:28
 * @LastEditTime: 2024-12-19 09:49:47
-->
<template>
  <div class="rtc-container">
    <!-- 进房操作区域 -->
    <!-- <p v-if="isHostMode" class="label">{{ $t('Operation') }}</p> -->
    <div @click="checkDevice"><span class="remarkText">若视频通话异常请点击此处进行设备检测<i class="el-icon-back"></i></span></div>
    <div class="control-container">
      <div class="rtc-control-container">
          <el-button
          class="button"
          type="primary"
          size="small" v-show="roomStatus != 'entered'" @click="handleJoinRoom">加入房间</el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary"
          size="small"
          @click="handlePublish">创建邀请链接</el-button>
           
        <!-- :disabled="isPublishing || isPublished"  -->
        <!-- <el-button
          v-if="isHostMode"
          class="button"
          type="primary" size="small" @click="handleUnpublish">{{ $t('Unpublish') }}</el-button> -->
        <el-button
          class="button"
          type="primary" v-show="roomStatus == 'entered'" size="small" @click="handleExit">离开房间</el-button>
        <el-button
          class="button"
          type="primary" v-show="roomStatus == 'entered' && isHostMode" size="small" @click="handleDismiss">解散房间</el-button>
        <el-button
          class="button"
          type="primary" size="small"  @click="Roominfo">房间信息</el-button>
      </div>
    </div>

    <!-- 显示邀请链接 -->
    <div v-if="showInviteLink" class="invite-link-container">
      <!-- <span v-if="isEnLang">Copy the link to invite friends to join the video call, one link can invite only one person,
        the link will be updated automatically after copying.</span>
      <span v-else>复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span> -->
      <el-input class="invite-input" v-model="inviteLink">
        <template slot="prepend">
          <el-tooltip
            :visibleArrow="false"
            effect="dark"
            content="Copied!"
            placement="bottom"
            :manual="true"
            v-model="showCopiedTip">
            <span class="invite-btn" v-clipboard:copy="inviteLink" v-clipboard:success="onCopy">
              <svg-icon icon-name="copy"></svg-icon>
            </span>
          </el-tooltip>
        </template>
      </el-input>
      <i class="el-icon-circle-close close-icon" @click="closeVisite"></i>
    </div>

    <!-- 调试区域 -->
    <!-- <div class="info-container" :class="$isMobile && 'info-container-mobile'"> -->
      <!-- Log 展示区域 -->
      <div v-show="debug" class="log-container" ref="logContainer">
        <p class="log-label">Log:</p>
        <div v-for="(item, index) in logList" :key="index">
          <span class="log-state" v-if="item.type === 'success'">🟩 </span>
          <span class="log-state" v-if="item.type === 'failed'">🟥 </span>
          <span>{{ item.log }}</span>
        </div>
      </div>

    <!-- 远端流区域 -->
    <div class="remote-container" v-show="roomStatus == 'entered'">
      <div class="local-stream-container">
        <!-- 本地流播放区域 -->
        <div id="local" class="local-stream-content"></div>
        <!-- 本地流操作栏 -->
        <div class="local-stream-control">
          <div class="video-control control">
            <span v-if="!isMutedVideo" @click="muteVideo">
              <svg-icon icon-name="video" class="icon-class"></svg-icon>
            </span>
            <span v-if="isMutedVideo"  @click="unmuteVideo">
              <svg-icon icon-name="video-muted" class="icon-class"></svg-icon>
            </span>
          </div>
          <div class="audio-control control">
            <span v-if="!isMutedAudio" @click="muteAudio">
              <svg-icon icon-name="audio" class="icon-class"></svg-icon>
            </span>
            <span v-if="isMutedAudio" @click="unmuteAudio">
              <svg-icon icon-name="audio-muted" class="icon-class"></svg-icon>
            </span>
          </div>
          <div class="audio-control control" @click="cam_change">
            <span>
              <img class="icon-img" src="@/assets/image/cam_change.png"/>
            </span>
          </div>
        </div>
      </div>
      <div class="remote-stream-container" v-for="(item,index) in remoteUsersViews" :key="item">
        <div style="width: 100%;height: 100%;" :id="item"></div>
        <div v-if="isHostMode" class="local-stream-control"  @click="removeTrtc(item)">移出</div>
      </div>
      
      <div v-if="isHostMode && camStatus === 'started'" class="remote-stream-container"  @click="handlePublish">
        <div class="add_icon">+</div>
      </div>
    </div>
  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import axios from "axios";
import config from '../config/config.js'
import TRTC from 'trtc-sdk-v5';
import { DeviceDetector } from 'trtc-sdk-v5/plugins/device-detector';

export default {
  name: 'compRoom',
  mixins: [rtc],
  props: {
    type: String,
    sdkAppId: Number,
    secretKey: String,
    userId: String,
    roomId: Number,
    inviteUserSig: String,
  },
  data() {
    return {
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
      visiteShow:false,
      isFrontCamera:true,
      cameraId:'',
      microphoneId:'',
      debug:false
    };
  },
  mounted() {
    this.trtc = TRTC.create();
    // this.cam_change()
    // this.micro_change()
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
    isEnLang() {
      return this.$i18n.locale === 'en';
    },
    showInviteLink() {
      // return this.isHostMode && (this.isJoined || this.isShareJoined) && this.inviteLink && this.visiteShow;
      return this.isHostMode && this.camStatus === 'started' && this.inviteLink && this.visiteShow;
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    }
  },
  methods: {
    //设备检查
    async checkDevice(){
      this.reportSuccessEvent('startPlugin');
      const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
      let userSig = userSigGenerator.genTestUserSig(this.userId);
      const trtc = TRTC.create({ plugins: [DeviceDetector] });
      // 2. Test Media Device & Network Quality
      const options = { 
          networkDetect: { sdkAppId:this.sdkAppId, userId:this.userId, userSig}
      }
      const result = await trtc.startPlugin('DeviceDetector', options);
      let resultText = '';
      if (result) {
        for (const key of Object.keys(result)) {
          resultText += `${key}: ${JSON.stringify(result[key], null, 2)}\n`;
        }
        this.reportSuccessEvent('successResultNet');
      }
      console.log("检查结果",resultText)
      return true
    },
    isOdd(index){
      return (index + 1) % 2 === 1;
    },
    closeVisite(){
      this.visiteShow = false
    },
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, secretKey, roomId } = this;
      // const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      // const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
      // const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?roomId=${roomId}`);
    },
    onCopy(){
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
    },
    //旧的复制方法，经常失效
    handleCopyInviteLink() {
      this.generateInviteLink();
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      if (this.isHostMode) {
        if (!this.sdkAppId || !this.secretKey) {
          alert(this.$t('Please enter sdkAppId and secretKey'));
          return;
        }
        if (!this.userId || !this.roomId) {
          alert(this.$t('Please enter userId and roomId'));
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
        //后端添加房间信息
        axios.post(config.apiUrl + 'trtc_api/add_room',{
          'room':this.roomId,
          'user':this.userId
        }).then(response=> {
          // 处理成功情况
          console.log(response);
          this.enterRoomCus()
        }).catch(function (error) {
          // 处理错误情况
          console.log(error);
          alert("房间信息创建失败，请重新再试")
        })
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
          alert(this.$t('Please reacquire the invitation link'));
          return;
        }
        this.userSig = this.inviteUserSig;
        //检查房间是否存在
        axios.post(config.apiUrl + 'trtc_api/has_room',{
          'room':this.roomId,
          'user':this.userId
        }).then( response=> {
          // 处理成功情况
          console.log(response);
          if(!response.data.data.data){
            alert('获取房间信息失败，请确认邀请链接的房间号再试');
            return;
          }
          this.enterRoomCus()
        }).catch(function (error) {
          // 处理错误情况
          console.log(error);
        })
      }
      
    },
    //进入房间
    async enterRoomCus(){
        await this.enterRoom();
        this.handleStartLocalAudio();
        this.handleStartLocalVideo();
        this.generateInviteLink();
        this.micro_change()
    },
    //退出房间
    async handleExit() {
      await this.exitRoom();
    },
    
    //房间信息
    Roominfo(){
      let html = `房间id:${this.roomId}<br/>当前用户id：${this.userId}<br/>`
      for(let i = 0;i<this.remoteUsersViews.length;i++){
        html += `用户${i+1}：${this.remoteUsersViews[i]}<br/>`
      }
      
      this.$alert(html,"房间信息",{
        dangerouslyUseHTMLString: true
      });
    },
    //解散房间
    handleDismiss(){
      axios.post(config.apiUrl + 'trtcApi/dismiss_room',{
        'room':this.roomId
      }).then(function (response) {
        // 处理成功情况
        console.log(response);
      })
      .catch(function (error) {
        // 处理错误情况
        console.log(error);
      })
    },
    //移出人员
    removeTrtc(userId){
      userId = userId.replace("_main",'')
      userId = userId.replace("_screen",'')
      axios.post(config.apiUrl + 'trtcApi/remove_person',{
        'roomId':this.roomId,
        'userIds':userId
      }).then(function (response) {
        // 处理成功情况
        console.log(response);
      })
      .catch(function (error) {
        // 处理错误情况
        console.log(error);
      })
    },
    // 点击【Publish】按钮
    async handlePublish() {
      if(!this.camStatus === 'started'){
        alert(this.$t('请进入房间后再创建邀请链接'));
      }else{
        this.generateInviteLink()
        this.visiteShow = true
        await this.publish();
      }
    },

    //切换摄像头
    async cam_change(){
      const cameraList = await TRTC.getCameraList()
      if(!cameraList[0]){
        alert(this.$t('没有可用摄像头'));
        return
      }
      let deviceId = cameraList[0].deviceId
      if(cameraList[1] && this.isFrontCamera){
        deviceId = cameraList[1].deviceId
        this.isFrontCamera = !this.isFrontCamera
      }
      this.cameraId = deviceId
    },
    async micro_change(){
      const microList = await TRTC.getMicrophoneList()
      if (microList[0]){
        for(let i=0;i<microList.length;i++){
          if(microList[i].label == 'speakerphone'){
            this.microphoneId = microList[i].deviceId
          }
        }
      }
    },
    

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.sdkSecretKey) {
        alert(this.$t('Please enter sdkAppId and sdkSecretKey'));
        return;
      }
      this.generateInviteLink();
    },

    addSuccessLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'success',
        log,
      });
    },

    addFailedLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'failed',
        log,
      });
    },

    reportSuccessEvent(name) {
      const ext3 = (name === 'enterRoom' || name==='successResultNet')? this.sdkAppId : 0;
      this.$aegis?.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: this.$DEMOKEY,
        ext3,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis?.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: this.$DEMOKEY,
        ext3: 0
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.remarkText{
  font-size: 14px;
  color: red;
}
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;

    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
    .close-icon{
      position: absolute;
      top:0;
      right:0;
    }
  }
  .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
  .remote-container {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .remote-stream-container {
      position: relative;
      width: 50%;
      height: 200px;
      background: #000;
      border: 1px solid #fff;
      // margin: 0 10px 10px 0;
      .add_icon{
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        line-height: 194px;
        text-align: center;
      }
    }
    .local-stream-container{
      width: 50%;
      height: 200px;
      position: relative;
      flex-shrink: 0;
      border: 1px solid #fff;
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
      
    }
    .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        color: #fff;
        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
        .icon-img{
          width: 30px;
          height: 20px;
        }
      }
  }
}
</style>

<i18n>
{
	"en": {
		"Operation": "Operation",
    "Join Room": "Join Room",
    "Publish": "Publish",
    "Unpublish": "Unpublish",
    "Leave Room": "Leave Room",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
		"Operation": "操作",
    "Join Room": "进入房间",
    "Publish": "发布流",
    "Unpublish": "取消发布流",
    "Leave Room": "离开房间",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "Please reacquire the invitation link": "请重新获取邀请链接！"
	}
}
</i18n>
